<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>IQA Resources</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <ul>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+1+Getting+started.mov" target="_blank">
                VLE Video 1 Getting Started
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+2+Add+and+Delete+Evidence.mov" target="_blank">
                VLE Video 2 Add and Delete Evidence
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+3+Add+and+Delete+Coursework.mov" target="_blank">
                VLE Video 3 Add and Delete Coursework
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+4+Resources.mov" target="_blank">
                VLE Video 4 Resources
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+5+Messages.mov" target="_blank">
                VLE Video 5 Messages
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/knowledge_handbook_l4_taqa_feb_2025.pdf" target="_blank">
                Knowledge handbook L4
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_5_assessor_overview_feb_2025.doc" target="_blank">
                Pocket 5 Assessor Overview
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_6_taqa_witness_form_feb_2025.doc" target="_blank">
                Pocket 6 TAQA Witness Form
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_2_6317_41_award_iqa_ilp_general_feb_2025.docx" target="_blank">
                Pocket 2 Award IQA ILP
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/taqa_level_4_powerpoint_feb_2025.pdf" target="_blank">
                TAQA Level 4 Powerpoint
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_1_the_assessment_process_my_role_and_responsibilities_feb_2025.docx" target="_blank">
                Worksheet 1 The Assessment Process My Roles and Responsibilities
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_2_iqa_planning_preparing_benefits_feb_2025.doc" target="_blank">
                Worksheet 2 IQA Planning Preparing Benefits
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_3_for_technology_reflective_practice_and_cpd_feb_2025.doc" target="_blank">
                Worksheet 3 Technology and CPD
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_4_for_taqa_legislation_and_requirements_feb_2025.doc" target="_blank">
                Worksheet 4 Legislation and Requirements
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IqaResources',
};
</script>

<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Assessing Award Resources</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <ul>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+1+Getting+started.mov" target="_blank">
                VLE Video 1 Getting Started
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+2+Add+and+Delete+Evidence.mov" target="_blank">
                VLE Video 2 Add and Delete Evidence
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+3+Add+and+Delete+Coursework.mov" target="_blank">
                VLE Video 3 Add and Delete Coursework
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+4+Resources.mov" target="_blank">
                VLE Video 4 Resources
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+5+Messages.mov" target="_blank">
                VLE Video 5 Messages
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Assessing_Knowledge_Handbook_TAQA_V_3.pdf" target="_blank">
                Assessing Knowledge Handbook
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/pocket_2_assessing_award_individual_learning_plan_ilp_general.docx" target="_blank">
                Assessing Award Individual Learning Plan General
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/pocket_2_assessing_award_individual_learning_plan_ilp_syfr_jan_2025.docx" target="_blank">
                Assessing Award Individual Learning Plan SYFR
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Learner_overview.doc" target="_blank">
                Learner Overview
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/pocket_8_taqa_witness_statement_for_assessors_jan_2025.doc" target="_blank">
                TAQA Witness Statement for Assessors
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/TAQA_L3_Presentation_2.pdf" target="_blank">
                TAQA L3 Presentation
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet_1_The_Assessment_process_My_roles_and_responsibilities.pdf" target="_blank">
                Worksheet One The Assessment Process My Roles and Responsibilities
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet_2_Functions_and_types_of_Assessment.pdf" target="_blank">
                Worksheet Two Functions and Types of Assessment
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/worksheet_three_assessment_methods_v2.pdf" target="_blank">
                Worksheet Three Assessment Methods
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet_4_Risks_involved_in_assessment.pdf" target="_blank">
                Worksheet Four Risks Involved in Assessment
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet_5_Factors_to_consider_when_planning.pdf" target="_blank">
                Worksheet Five Factors to Consider When Planning
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet_6_Quality_assurance_and_disputes.pdf" target="_blank">
                Worksheet Six Quality Assurance and Disputes
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.amazonaws.com/simple_vle/resources/Worksheet 7 Legislation and regulatory requirements_2.pdf" target="_blank">
                Worksheet Seven Legislation and Regulatory Requirements
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssessingAwardResources',
};
</script>
